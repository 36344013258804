import React from 'react'
import PropTypes from 'prop-types'
import pic01 from '../images/pic01.jpg'

class Blog extends React.Component {

    state = {
        articles: []
    }

    componentWillMount(){
        this.getBlogData();
    }

    getBlogData = async () => {
        const blogDataUrl = `https://public-api.wordpress.com/wp/v2/sites/kellycoleman110323138.wordpress.com/posts?pretty=true`;
        await fetch(blogDataUrl)
                .then(response => response.json())
                .then(articles => this.setState({ articles }));
        console.log('blog state', this.state);
    }

    render() {
        const { articles } = this.state;
        return (
            <article id="blog" className={`${this.props.article === 'blog' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
                <h2 className="major">Blog</h2>
                <span className="image main"><img src={pic01} alt="" /></span>
                { articles.map((article,i)=><div className="blog" key={`blog-entry-${i}`}><h2>{article.title.rendered}</h2><h4>{new Date(article.date).toDateString()}</h4><div dangerouslySetInnerHTML={{__html: article.content.rendered}}></div><br/></div>)}
                {this.props.close}
            </article>
        )
    }
}

Blog.propTypes = {
    timeout: PropTypes.bool
}

export default Blog