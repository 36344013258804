import React from 'react'
import PropTypes from 'prop-types'

import { openInNewTab, pageView } from '../methods/url'

const Footer = props => (
  <footer
    className="no-select"
    id="footer"
    style={props.timeout ? { display: 'none' } : {}}
  >
    <ul className="icons footer">
      <li>
        <a
          href="https://m.me/kelly.cotta.31'"
          target="_blank"
          onClick={a => {
            a.preventDefault()
            openInNewTab('https://m.me/kelly.cotta.31', '/facebook-messenger')
          }}
        >
          <i className="fab fa-facebook-messenger"></i>
          <span style={{ display: 'none' }} className="label">
            Facebook Messenger
          </span>
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/kelly.cotta.31"
          target="_blank"
          onClick={a => {
            a.preventDefault()
            openInNewTab(
              'https://www.facebook.com/kelly.cotta.31',
              '/facebook-profile'
            )
          }}
          className="icon fa-facebook"
        >
          <span className="label">Facebook</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/kellybellybee"
          target="_blank"
          onClick={a => {
            a.preventDefault()
            openInNewTab(
              'https://www.instagram.com/kellybellybee',
              '/instagram'
            )
          }}
          className="icon fa-instagram"
        >
          <span className="label">Instagram</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.amazon.com/handmade/Kellys-Collection"
          target="_blank"
          onClick={a => {
            a.preventDefault()
            openInNewTab(
              'https://www.amazon.com/handmade/Kellys-Collection',
              '/amazon'
            )
          }}
          className="icon fa-amazon"
        >
          <span className="label">Amazon Handmade</span>
        </a>
      </li>
      <li>
        <a
          href="https://www.pinterest.com/KellysCollections/pins/"
          target="_blank"
          onClick={a => {
            a.preventDefault()
            openInNewTab(
              'https://www.pinterest.com/KellysCollections/pins/',
              '/pintrest'
            )
          }}
          className="icon fa-pinterest"
        >
          <span className="label">Pintrest</span>
        </a>
      </li>
      <li>
        <a
          href="https://etsy.com/shop/JustAnotherShirtShop"
          target="_blank"
          onClick={a => {
            a.preventDefault()
            openInNewTab('https://etsy.com/shop/JustAnotherShirtShop', '/etsy')
          }}
          className="icon fa-etsy"
        >
          <span className="label">Etsy</span>
        </a>
      </li>
      <li>
        <a
          href="/contact"
          onClick={a => {
            a.preventDefault()
            pageView('/contact')
            props.onOpenArticle('contact')
          }}
          className="icon fa-envelope"
        >
          <span className="label">Email</span>
        </a>
      </li>
    </ul>
    <p className="copyright">&copy; Kelly Coleman</p>
  </footer>
)

Footer.propTypes = {
  timeout: PropTypes.bool,
}

export default Footer
