import React from 'react'
import PropTypes from 'prop-types'

import { openInNewTab, pageView } from '../methods/url'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      {/* <span className="icon fa-diamond"></span> */}
    </div>
    <div className="content">
      <div className="inner no-select">
        <h1>Kelly Coleman</h1>
        <p>Mother, Wife, Small Business Owner.</p>
      </div>
    </div>
    <nav className="no-select">
      <ul>
        <li>
          <a
            href="/about"
            onClick={a => {
              a.preventDefault()
              props.onOpenArticle('about')
            }}
          >
            About
          </a>
        </li>
        <li>
          <a
            href="https://kelscollection.com"
            target="_blank"
            onClick={a => {
              a.preventDefault()
              openInNewTab('https://kelscollection.com', '/shopify')
            }}
          >
            Store
          </a>
        </li>
        <li>
          <a
            href="https://www.etsy.com/shop/JustAnotherShirtShop"
            target="_blank"
            onClick={a => {
              a.preventDefault()
              openInNewTab(
                'https://www.etsy.com/shop/JustAnotherShirtShop',
                '/etsy'
              )
            }}
          >
            Etsy
          </a>
        </li>
        <li>
          <a
            href="https://www.amazon.com/handmade/Kellys-Collection"
            target="_blank"
            onClick={a => {
              a.preventDefault()
              openInNewTab(
                'https://www.amazon.com/handmade/Kellys-Collection',
                '/amazon'
              )
            }}
          >
            Amazon
          </a>
        </li>
        <li>
          <a
            href="https://kellycoleman.mynuskin.com"
            target="_blank"
            onClick={a => {
              a.preventDefault()
              openInNewTab('https://kellycoleman.mynuskin.com', '/store')
            }}
          >
            NuSkin
          </a>
        </li>
        <li>
          <a
            href="/contact"
            onClick={a => {
              a.preventDefault()
              pageView('/contact')
              props.onOpenArticle('contact')
            }}
          >
            Contact
          </a>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
