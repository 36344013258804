import React from 'react'
import PropTypes from 'prop-types'
import pic01 from '../images/pic01.jpg'
import banner from '../images/about-banner.jpg'
import { graphql, StaticQuery } from "gatsby"



class About extends React.Component {

    state = {
        articles: [],
        ready: false
    }

    componentWillMount(){
        this.getBlogData();
    }

    getBlogData = async () => {
        const blogDataUrl = `https://public-api.wordpress.com/wp/v2/sites/kellycoleman110323138.wordpress.com/pages/42?pretty=true`;
        await fetch(blogDataUrl)
                .then(response => response.json())
                .then(articles => this.setState({ articles, ready: true }));
        console.log('about me:', this.state);
    }

    defaultView = () => {
        return (
            <StaticQuery
            query={graphql`
            {
                allWordpressPost {
                  edges {
                    node {
                      id
                      slug
                      title
                      content
                      excerpt
                      date
                      modified
                    }
                  }
                }
              }
        `}
            render={data => (
                <>
                    <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
                        <h2 className="major">About Me</h2>
                        <span className="image main"><img src={banner} alt="" /></span>
                        <p>Kelly Coleman</p>
                        {this.props.close}
                    </article>
                </>
            )}
        />
        )
    }

    render() {
        const { articles, ready } = this.state;
        console.log('articles lenght', articles, this.state);
        return (!articles || articles.length < 1) ? this.defaultView() :
        (
            <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
                <h2 className="major">About Me</h2>
                <span className="image main"><img src={banner} alt="" /></span>
                { ready ? <div dangerouslySetInnerHTML={{__html: articles.content.rendered}} /> : ""}
                {this.props.close}
            </article>
        )
    }
}

About.propTypes = {
    timeout: PropTypes.bool
}

export default About