import React from 'react'
import PropTypes from 'prop-types'


import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
// import pic03 from '../images/pic03.jpg'
import { GoogleReCaptchaProvider, useGoogleReCaptcha, GoogleReCaptcha } from 'react-google-recaptcha-v3';

import Blog from './Blog'
import About from './About'

// eslint-disable-next-line
const emailRegex = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;

let recaptchaInstance;

class Main extends React.Component {

  state = {
    name: false, email: false, message: false, captcha: false, activeDiv: null
  }

  // specifying verify callback function
  verifyCallback = async (response) => {
    try {
      console.log('verify callback response', response, document.getElementById("g-recaptcha-response").value);
      if (response !== document.getElementById("g-recaptcha-response").value) {
        recaptchaInstance.reset();
        return console.log('captcha not match');
      } else {
        await this.setState({ ...this.state, captcha: true });
      }

    } catch (err) {
      console.error('error in verify callback', err);
    }
  };

  submitForm = async () => {
    const { name, email, message } = this.state;
    var script = document.createElement('script');
    var script2 = document.createElement('script');
    script.src = `https://maker.ifttt.com/trigger/Contact/with/key/gd85d9SHyDXmCvMM9OhlQTK4S8nhIqF8UHHPhfcdQZC?value1=${encodeURIComponent(name)}&value2=${encodeURIComponent(email)}%202&value3=${encodeURIComponent(message)}`;
    script2.src = `https://maker.ifttt.com/trigger/ContactPush/with/key/gd85d9SHyDXmCvMM9OhlQTK4S8nhIqF8UHHPhfcdQZC?value1=${encodeURIComponent(name)}&value2=${encodeURIComponent(email)}%202&value3=${encodeURIComponent(message)}`;
    document.getElementsByTagName('head')[0].appendChild(script);
    document.getElementsByTagName('head')[0].appendChild(script2);
    await this.props.onCloseArticle();
    await setTimeout(() => {
      this.props.onOpenArticle('submission');
    }, 700)
  }

  executeCaptcha = (a) => {
    console.log('execute captcha', a);
    window.a = a;
    window.captcha = recaptchaInstance;
    recaptchaInstance.execute();

  };

  onChange = (v, e) => {
    const { name, value } = v.currentTarget;
    this.setState({ ...this.state, [name]: value })
  }

  componentDidCatch = (err) => {
    console.error('component did catch', err);
  }

  componentWillUnmount = () => {
    recaptchaInstance.reset();
    // recaptchaInstance = undefined;
  }

  componentWillReceiveProps = (newProps) => {
    if (newProps.article === "contact") {
      document.getElementById("name").focus();
    }
  }


  componentDidMount = () => {
    window.r3use = useGoogleReCaptcha;
  }

  render() {
    if (this.props.article === "contact") window.scrollTo(0, 10);
    let close = <div className="close" onClick={() => { this.props.onCloseArticle(); this.setState({...this.state, email: false, name: false, message: false, captcha: false}) }}></div>
    const { email, message, captcha, activeDiv } = this.state;
    const fname = this.state.name;
    return (
      <GoogleReCaptchaProvider reCaptchaKey="6LfDPZcUAAAAAK8h7yBBNMvko-f7NXVBoArDSLj4">
        <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}>

          <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
            <h2 className="major">Intro</h2>
            <span className="image main"><img src={pic01} alt="" /></span>
            <p>Aenean ornare velit lacus, ac varius enim ullamcorper eu. Proin aliquam facilisis ante interdum congue. Integer mollis, nisl amet convallis, porttitor magna ullamcorper, amet egestas mauris. Ut magna finibus nisi nec lacinia. Nam maximus erat id euismod egestas. By the way, check out my <a href="#work">awesome work</a>.</p>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis dapibus rutrum facilisis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Etiam tristique libero eu nibh porttitor fermentum. Nullam venenatis erat id vehicula viverra. Nunc ultrices eros ut ultricies condimentum. Mauris risus lacus, blandit sit amet venenatis non, bibendum vitae dolor. Nunc lorem mauris, fringilla in aliquam at, euismod in lectus. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In non lorem sit amet elit placerat maximus. Pellentesque aliquam maximus risus, vel sed vehicula.</p>
            {close}
          </article>

          <Blog articleTimeout={this.props.articleTimeout} article={this.props.article} close={close} />

          <About articleTimeout={this.props.articleTimeout} article={this.props.article} close={close} />

          <article id="work" className={`${this.props.article === 'work' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
            <h2 className="major">Work</h2>
            <span className="image main"><img src={pic02} alt="" /></span>
            <p>Under development...</p>
            <p>Coming soon.</p>
            {close}
          </article>

          {/* <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">About</h2>
          <span className="image main"><img src={pic03} alt="" /></span>
          <p>Under development...</p>
          {close}
        </article> */}



          <article id="submission" className={`${this.props.article === 'submission' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
            <h2 className="major">Message Sent</h2>
            <p style={{ textAlign: 'center' }}>Your message has been sent!</p>
            <p style={{ textAlign: 'center' }}>Please allow 24-48 hours for a reply.</p>
            <p className="signature" style={{ textAlign: 'center' }}>Sincerely,<br/><span style={{fontFamily: "Herr Von Muellerhoff", fontSize: "2.5rem"}}>Kelly Coleman</span></p>
            {close}
          </article>

          <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
            <h2 className="major">Email Me</h2>
            <form id="contactForm" method="post" action="#" onReset={() => this.setState({ name: false, email: false, message: false })}>
              <div className="field half first">
                <label htmlFor="name">Your Name</label>
                <input 
                  className={this.state.name.length < 2 && activeDiv !== "name" ? "error" : ""} 
                  onFocus={() => this.setState({ ...this.state, activeDiv: "name" })} 
                  onBlur={() => this.setState({ ...this.state, activeDiv: null })}                 
                  autoFocus={true} type="text" name="name" id="name" onChange={this.onChange} value={this.state.name ? this.state.name : ''} placeholder={this.state.name.length === 0 ? 'Please enter your name' : ''} />
              </div>
              <div className="field half">
                <label htmlFor="email">Your Email</label>
                <input 
                  className={email.length >= 0 && !emailRegex.test(email) && activeDiv !== "email" ? "error" : ""} 
                  onFocus={() => this.setState({ ...this.state, activeDiv: "email" })} 
                  onBlur={() => this.setState({ ...this.state, activeDiv: null })} 
                  type="text" name="email" id="email" onChange={this.onChange} value={this.state.email ? this.state.email : ''} />
              </div>
              <div className="field">
                <label htmlFor="message">Message</label>
                <textarea 
                  className={message.length <= 2 && activeDiv !== "message" ? "error" : ""} 
                  onFocus={() => this.setState({ ...this.state, activeDiv: "message" })} 
                  onBlur={() => this.setState({ ...this.state, activeDiv: null })} 
                  name="message" id="message" rows="4" onChange={this.onChange} maxLength={300} value={this.state.message ? this.state.message : ''}></textarea>
                <div>{message.length > 0 ? message.length + "/300" : ""}&nbsp;</div>
              </div>
              <div className="field">
                <GoogleReCaptcha onVerify={token => this.setState({ ...this.state, captcha: true })} />
                {/* <Recaptcha
                ref={(inst)=>recaptchaInstance = inst}
                sitekey={`6LcpPpcUAAAAAIfANfFBDWMObHO0FQztQMN_R-ol`}
                verifyCallback={this.verifyCallback}
                expiredCallback={()=>recaptchaInstance.reset()}
              /> */}
              </div>
              <ul className="actions">
                <li><input type="button" onClick={this.submitForm} value="Send Message" className="special" disabled={(!fname || !email || !emailRegex.test(email) || !message || !captcha) ? true : false} /></li>
                <li><input type="reset" value="Reset" /></li>
              </ul>
            </form>
            <ul className="icons">
              {/* <li><a href="#twitter" className="icon fa-twitter"><span className="label">Twitter</span></a></li> */}
              {/* <li><a href="https://m.me/kelly.cotta.31" target="_blank"><i className="fab fa-facebook-messenger"></i><span style={{display: 'none'}} className="label">Facebook Messenger</span></a></li> */}
              {/* <li><a href="https://m.me/kelly.cotta.31" className="icon fab fa-facebook-messenger"><span className="label">Facebook</span></a></li> */}
              {/* <li><a href="#instagram" className="icon fa-instagram"><span className="label">Instagram</span></a></li> */}
              {/* <li><a href="mailto:kelly@kellycoleman.me" className="icon fa-envelope"><span className="label">Email</span></a></li> */}
            </ul>
            {close}
          </article>

        </div>
      </GoogleReCaptchaProvider>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main