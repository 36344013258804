import ReactGA from 'react-ga'

export const openInNewTab = (url, pageViewPath) => {
  try {
    ReactGA.pageview(pageViewPath)
    var win = window.open(url, '_blank')
    win.focus()
  } catch (e) {
    console.error('e', e)
  }
}

export const pageView = pageViewPath => {
  try {
    ReactGA.pageview(pageViewPath)
  } catch (e) {
    console.log('e', e)
  }
}
